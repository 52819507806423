import { $win } from '../utils/globals';

const $category = $('.widget__title');
const $sidebar = $('.woocommerce .sidebar');
const $widgets = $('.woocommerce .widgets');


//Show categories

$category.each(function() {
	$(this).on('click', (e) => {
		e.preventDefault();

		const $el = $(e.currentTarget);

		$el.siblings('.woocommerce-widget-layered-nav-list').slideToggle();
		$el.toggleClass('is-active');
	});
});

if ( $('.woocommerce-widget-layered-nav-list__item--chosen').length ) {
	$('.woocommerce-widget-layered-nav-list__item--chosen').each( function() {
		let $this = $(this);

		$this.closest('.woocommerce-widget-layered-nav').find('.widget__title').trigger('click');
	});
}

let current_url = window.location.origin + window.location.pathname;

if ( $('.widgets').find('a[href="' + current_url + '"]').length ) {
	let $el = $('.widgets')
		.find('a[href="' + current_url + '"]')
			.closest('li.current-cat')
	if ( !$el.hasClass( 'cat-parent') ) {
		$el.closest("li.widget:not('.woocommerce-widget-layered-nav')")
			.find('.widget__title')
			.trigger('click');
	}
}


//Categories mobile

$win.on('load', () => {
	console.log(window.location.href)

	if (window.location.href.indexOf('brand') > -1) {
		$('body').addClass('has-brand-title')
	}

	const $filterBtn = $('<button class="btn-filter js-btn-filter"> Filters </button>');
	const $btnCloseWidgets = $('<button class="btn-close-widgets js-btn-close-widgets"></button>');

	$sidebar.prepend($filterBtn);
	$widgets.prepend($btnCloseWidgets);

	$filterBtn.on('click', (e) => {
		const $el = $(e.currentTarget);

		$el.siblings('.widgets').toggleClass('is-active');
	});

	$btnCloseWidgets.on('click', (e) => {
		const $el = $(e.currentTarget);

		$el.closest('.widgets').removeClass('is-active');
	});


})


