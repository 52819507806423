import {$win, $doc} from '../utils/globals';

$win.on('load scroll orientationChange', () => {

	if($('html').scrollTop() >= $('.js-header').outerHeight()) {
		$('body').addClass('header-is-sticky');
	} else {
		$('body').removeClass('header-is-sticky');
	}
})
