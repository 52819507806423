var isAjax = false;

$('a[href="#redirect-to-condition"]').on('click', function( e ) {
	e.preventDefault();
	let $this = $(this);
	let visitTypeUuid = ( $this.data( 'visit-type-uuid' ) ) ? $this.data( 'visit-type-uuid' ) : '';

	const settings = {
		url: crb.ajax,
		data: {
			action: 'crb_generate_visit_redirect',
			visit_type_uuid: visitTypeUuid,
		},
		beforeSend: function() {
			isAjax = true;
		}
	};

	if ( !isAjax ) {
		$.ajax(settings)
			.done( function( response ) {
				if ( response.data.url ) {
					window.location.href = response.data.url;
				}
			})
			.fail(function( error ) {
				console.log('Error:' + error);
			})
			.complete(function() {
				isAjax = false;
			});
	}
});