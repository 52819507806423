const initAccordions = ($accordion = $('.js-accordion-faq')) => {
	$('.accordion__section').not('.is-active').find('.accordion__body').hide()

	$accordion.on('click', '.accordion__head', function (event) {
		const $accordionSection = $(this).closest('.accordion__section');
		const $accordionBody = $accordionSection.find('.accordion__body');

		$accordionBody.stop().slideToggle();

		$accordionSection.toggleClass('is-active');

		$accordionSection.siblings().removeClass('is-active')
				.find('.accordion__body').slideUp();

	});
}

export default initAccordions;

initAccordions();
