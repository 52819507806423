import {$win} from '../utils/globals';

if ($('.section-condition-details').length) {
	$('.section-condition-details .section__content').each(function() {
		let element = $(this);

		let link = element.find('.btn--learn');
		let textTag = link.prev();
		textTag.append(link);
	});
}