import { $win } from '../utils/globals';

const winLochash = window.location.hash;

function scrollByData( data ) {
	if ( ! data ) {
		return false;
	};

	var headerHeight = $('.header').outerHeight() || $('.site-header').outerHeight();
	const $scrollTarget = $( '*[data-id="' + data + '"]');

	if ( $scrollTarget.length > 0 ) {
		$('html, body').animate({
			scrollTop: $scrollTarget.offset().top - headerHeight,
		}, 1000);
	};
}

$('.js-nav-scroll-to a').each(function () {
	const $this = $(this);
	const href = this.hash;

	if (this.hash) {

		$(this).on('click', function(event) {
			scrollByData( href );
		});
	}
});
