/*
 * Modules
 */

import './modules/sticky-nav';
import './modules/slider-cards';
import './modules/slider-testimonials';
import './modules/slider-testimonials-v3';
import './modules/slider-awards';
import './modules/slider-members';
import './modules/slider-conditions';
import './modules/slider-articles';
import './modules/navigation';
import './modules/nav-mobile';
import './modules/deep-linking-conditions';
import './modules/deep-linking-condition-single';
import './modules/select-blog';
import './modules/animations';
import './modules/add-learn-button';
import './modules/insert-content-quantity';
import './modules/telehealth-redirect-button';
import './modules/clickEvent';
import './modules/filters-mobile';
import './modules/sidebar-accordion';
import './modules/init-getprescription';

import initAccordions from './modules/accordion-faq';

//Wocommerce
import './modules/shop';
import './modules/produtct-single-prices';
import './modules/product-transfer-link';


