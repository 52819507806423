import { $win } from '../utils/globals';

$win.on('load', () => {
	const $product = $('.quantity');

	$product.each(function() {
		let $btnPlus = $('<button class="btn-plus js-btn-plus"> </button>')
		let $btnMinus = $('<button class="btn-minus is-disabled js-btn-minus"> </button>')

		$(this).prepend($btnMinus);
		$(this).append($btnPlus);
	})

	$product.find('.btn-plus').on('click', (e) => {
		e.preventDefault();

		const $el = $(e.currentTarget);
		const inputField = $el.siblings('.input-text');
		let newVal = parseInt(inputField.val()) + 1;

		newVal <= 1 ? $el.siblings('.btn-minus').addClass('is-disabled') : $el.siblings('.btn-minus').removeClass('is-disabled');

		$('button[name="update_cart').prop('disabled', false);
		inputField.val(newVal);
	})

	$product.find('.btn-minus').on('click', (e) => {
		e.preventDefault();

		const $el = $(e.currentTarget);
		const inputField = $el.siblings('.input-text');
		let newVal = parseInt(inputField.val()) > 1 ? parseInt(inputField.val()) - 1 : 1;

		$('button[name="update_cart').prop('disabled', false);

		newVal <= 1 ? $el.addClass('is-disabled') : $el.removeClass('is-disabled');
		inputField.val(newVal);
	})
})
