import Swiper from 'swiper/swiper-bundle';

$('.js-slider-members .swiper').each((i, slider) => {
	const $slider = $(slider).closest('.js-slider-members');
	const pagination = $slider.find('.swiper-pagination')[0];

	if ($slider.find('.slider__slide').length > 3) {
		$slider.find('.swiper-pagination').addClass('is-active');
	}

	const swiper = new Swiper(slider, {
		pagination: {
		    el: pagination,
		    type: 'bullets',
		},
		breakpoints: {
			300: {
				slidesPerView: 1,
				slidesPerGroup: 1,
				spaceBetween: 10,
				centeredSlides: true,
			},
			375: {
				slidesPerView: 1.21,
				slidesPerGroup: 1,
				spaceBetween: 29,
				centeredSlides: false,
				loop: false,
			},
			767: {
				slidesPerView: 2,
				slidesPerGroup: 1,
				spaceBetween: 10,
				centeredSlides: false,
				loop: false,
			},
			1023: {
				slidesPerView: 3,
				slidesPerGroup: 1,
				spaceBetween: 30,
			},

			1400: {
				slidesPerView: 3,
				slidesPerGroup: 1,
				spaceBetween: 92,
				watchOverflow: true,
			}
		}
	});
});
