import 'select2';
import { $win } from '../utils/globals'

$('.js-select-blog').select2({
	minimumResultsForSearch: Infinity,
});

$('.js-select-blog').on('change', function(){
	const $filterForm = $('.js-category-filter');

	if ( ! $filterForm.length ) {
		return;
	}

	$filterForm.attr('action', $(this).val());

	$('.js-category-filter').trigger('submit');
});
