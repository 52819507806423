import {$win} from '../utils/globals';


if($win.width() < 1024) {
	$('.js-nav > .menu > li > a').on('click', function () {
		$(this).parent().addClass('is-second-level');
	});

	$('.js-nav-back').on('click', function (e) {
		e.preventDefault();

		$(this).closest('.menu-item-has-children').removeClass('is-second-level');
	})

	$('.js-nav-dd-title').each(function() {

		$(this).on('click', () => {
			$(this).siblings('.nav__dd-body').slideToggle();
			$(this).toggleClass('is-active');
		})
	})
}
