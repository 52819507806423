import { $win } from '../utils/globals';

const winLochash = window.location.hash;

function scrollByData( data ) {
	if ( ! data ) {
		return false;
	};

	var headerHeight = ( $('.header').outerHeight() || $('.site-header').outerHeight() ) - 15;

	if ( $( '*[data-id="' + data + '"]' ).length > 0 ) {

		$('body, html').animate({
			scrollTop: $( '*[data-id="' + data + '"]' ).offset().top - headerHeight
		}, 1000);
	};
}

$('.js-nav-conditions a').each(function () {
	const $this = $(this);
	const href = this.hash;

	if (this.hash) {

		$(this).on('click', function(event) {
			scrollByData( href );
		});
	}
});
