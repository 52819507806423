import Swiper from 'swiper/swiper-bundle';

$('.js-slider-articles .swiper').each((i, slider) => {
	const $slider = $(slider).closest('.js-slider-articles');
	const nextEl = $slider.find('.swiper-button-next')[0];
	const prevEl = $slider.find('.swiper-button-prev')[0];
	const pagination = $slider.find('.swiper-pagination')[0];

	if ($slider.find('.slider__slide').length > 3) {
		$slider.find('.swiper-pagination').addClass('is-active');
	}

	const swiper = new Swiper(slider, {
		pagination: {
			el: pagination,
			type: 'bullets',
		},
		breakpoints: {
			300: {
				slidesPerView: 1,
				slidesPerGroup: 1,
				spaceBetween: 10,
				centeredSlides: true,
			},
			375: {
				slidesPerView: 1.28,
				slidesPerGroup: 1,
				spaceBetween: 22,
				centeredSlides: true,
				navigation: false,
				loop: true,
			},
			812: {
				slidesPerView: 3,
				slidesPerGroup: 1,
				spaceBetween: 10,
				centeredSlides: false,
				navigation: false,
				loop: false
			},
			1023: {
				slidesPerView: 3,
				slidesPerGroup: 1,
				spaceBetween: 30,
			},
			1400: {
				slidesPerView: 3,
				slidesPerGroup: 1,
				spaceBetween: 48,
				watchOverflow: true,
			}
		}
	});
});
