const $sidebar = $('.js-sidebar');

if ($sidebar.length > 0) {
	$sidebar.on('click', function(event) {
		const $this = $(this);

		if ( $this[0] == event.target ) {
			$this.toggleClass('is-open');
			$('body').toggleClass('is-locked');
		}
	})

	window.addEventListener('scroll', ( event ) => {
		const scroll = window.scrollY;
		
		scroll > 50 ? $sidebar.addClass('is-scrolled') : $sidebar.removeClass('is-scrolled');

	});
}