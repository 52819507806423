import Swiper from 'swiper/swiper-bundle';

$('.js-slider-testimonials-v3 .swiper').each((i, slider) => {
	const $slider = $(slider).closest('.js-slider-testimonials-v3');
	const buttonNext = $slider.find('.swiper-button-next')[0];
	const buttonPrev = $slider.find('.swiper-button-prev')[0];
	const buttonDots = $slider.find('.swiper-pagination')[0];

	const swiper = new Swiper(slider, {
		loop: true,
		effect: 'fade',
		autoHeight: true,
		fadeEffect: {
			crossFade: true
		},
		slidesPerView: 1,
		slidesPerGroup: 1,
		spaceBetween: 24,
		autoplay: {
			delay: 5000
		},
		pagination: {
			el: buttonDots,
			type: 'bullets',
			clickable: true,
		},
		navigation: {
			nextEl: buttonNext,
			prevEl: buttonPrev
		}
	});

	$(slider).on('click', '.js__toggle', function(event) {
		event.preventDefault();

		$(this).parents('.js__slide').toggleClass('active');

		swiper.update();
	});
});
