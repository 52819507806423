import {$win} from '../utils/globals';

import AOS from 'aos';

$win.on('load scroll', ()=> {
	AOS.init({
		duration: 800,
		delay: 50,
		once: true,
		offset: 50,
	});
})
