$(window).on('load', function () {
	setTimeout(function() {
		$('body').addClass('is-loaded');
	}, 100);

	const defaultValues = [];

	$('.button-variable-item.selected').each( function () {
		defaultValues.push($(this).data('title'));
	})

	if (! $('.product-details .single_variation_wrap .woocommerce-variation-price').is(':empty')) {
		if(
			$('.product-details form.variations_form > div .single_variation_wrap .single_variation:visible').length &&
			$('.product-details form.variations_form > div .single_variation_wrap .single_variation .woocommerce-variation-price').html()
		) {
			$('body').addClass('hide-default-price')
		} else {
			$('body').removeClass('hide-default-price')
		}
	}

	$('body').on('click', function (e) {
		console.log('clicked');
		const currentValues = []
		$('.button-variable-item.selected').each( function () {
			currentValues.push($(this).data('title'));
		});

		if (JSON.stringify(currentValues) === JSON.stringify(defaultValues)) {
			$('body').removeClass('hide-comparison');
		} else {
			$('body').addClass('hide-comparison');
		}

		if(
			$('.product-details form.variations_form > div .single_variation_wrap .single_variation:visible').length &&
			$('.product-details form.variations_form > div .single_variation_wrap .single_variation .woocommerce-variation-price').html()
		) {
			$('body').addClass('hide-default-price')
		}

		if ((($(e.target).closest('.product-details .reset_variations').length > 0 )))  {
			$('body').removeClass('hide-default-price')
			$('body').removeClass('hide-comparison');
		}
	});

})
