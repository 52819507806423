import Swiper from 'swiper/swiper-bundle';

$('.js-slider-conditions .swiper').each((i, slider) => {
	const $slider = $(slider).closest('.js-slider-conditions');
	const nextEl = $slider.find('.swiper-button-next')[0];
	const prevEl = $slider.find('.swiper-button-prev')[0];
	const pagination = $slider.find('.swiper-pagination')[0];
	console.log(nextEl)
	const swiper = new Swiper(slider, {
		slidesPerView: 1,
		slidesPerGroup: 1,
		spaceBetween: 48,
		watchOverflow: true,
		pagination: {
			el: pagination,
			type: 'bullets',
		  },
		navigation: {
			nextEl,
			prevEl,
		},
		breakpoints: {
			375: {
				slidesPerView: 1,
				slidesPerGroup: 1,
				spaceBetween: 48,
				watchOverflow: true,
				pagination: false,
				navigation: false,
			},
			767: {
				slidesPerView: 1,
				slidesPerGroup: 1,
				spaceBetween: 48,
				watchOverflow: true,
				pagination: {
					el: pagination,
					type: 'bullets',
				  },
			},
		}
	});
});
