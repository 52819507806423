import Swiper from 'swiper/swiper-bundle';

$('.js-slider-cards .swiper').each((i, slider) => {
	const $slider = $(slider).closest('.js-slider-cards');
	const pagination = $slider.find('.swiper-pagination')[0];

	if ($slider.find('.slider__slide').length > 4) {
		$slider.find('.swiper-pagination').addClass('is-active');
	}

	const swiper = new Swiper(slider, {
		slidesPerView: 4,
		slidesPerGroup: 1,
		spaceBetween: 48,
		watchOverflow: true,
		pagination: {
			el: pagination,
			type: 'bullets',
		},
		breakpoints: {
			300: {
				slidesPerView: 1,
				slidesPerGroup: 1,
				spaceBetween: 10,
				centeredSlides: true,
			},
			375: {
				slidesPerView: 1.25,
				slidesPerGroup: 1,
				spaceBetween: 17,
				centeredSlides: true,
				loop: true
			},
			767: {
				slidesPerView: 2,
				slidesPerGroup: 1,
				spaceBetween: 10,
				centeredSlides: false,
				loop: false,
			},
			1023: {
				slidesPerView: 3,
				slidesPerGroup: 1,
				spaceBetween: 30,
			},

			1400: {
				slidesPerView: 4,
				slidesPerGroup: 1,
				spaceBetween: 48,
			}
		}
	});
});
