import Swiper from 'swiper/swiper-bundle';

$('.js-slider-testimonials .swiper').each((i, slider) => {
	const $slider = $(slider).closest('.js-slider-testimonials');
	const pagination = $slider.find('.swiper-pagination')[0];
	console.log(pagination)

	const swiper = new Swiper(slider, {
		slidesPerView: 3,
		slidesPerGroup: 1,
		spaceBetween: 48,
		watchOverflow: true,
		pagination: {
		    el: pagination,
		    type: 'bullets',
		},
		breakpoints: {
			300: {
				slidesPerView: 1,
				slidesPerGroup: 1,
				spaceBetween: 17,
				centeredSlides: true,
			},
			375: {
				slidesPerView: 1.25,
				slidesPerGroup: 1,
				spaceBetween: 17,
				centeredSlides: true,
				loop: true
			},
			767: {
				slidesPerView: 2,
				slidesPerGroup: 1,
				spaceBetween: 17,
				centeredSlides: true,
				loop: true
			},

			1400: {
				slidesPerView: 2.05,
				centeredSlides: true,
				loop: true,
				slidesPerGroup: 1,
				spaceBetween: 46,
			}
		}
	});
});
