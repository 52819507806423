var attributes = {};
var base_link = $('.product-pg-cta1 a.btn-main').attr('href');

$( '.variations_form table.variations .woo-variation-raw-select' ).on( 'change', function () {
    let $attribute_select = $(this);

    setTimeout(() => {
        $attribute_select.closest('tbody').find('tr').each( function () {
            let $tr = $(this);
            $attribute_name = $tr.find('th.label label').attr('for');
            $attribute_value = $tr.find('li[aria-checked="true"] span.variable-item-span-button').html();
            
            attributes[ $attribute_name ] = $attribute_value;
        });
        parameters = 'medication-name=' + $('.product-details h2').html();

        for ( const [index, value] of Object.entries( attributes )) {
            if ( index === "pa_size" && value ) {
                parameters += '&medication-size=' + value;
            } else if ( index === "pa_strength" && value ) {
                parameters += '&medication-strength=' + value;
            }
        }

        $('.product-pg-cta1 a.btn-main').attr('href', base_link + '?' + parameters );
    }, 100 );
});

$( '.variations_form table.variations .woo-variation-raw-select' ).trigger('change');